import React from 'react';
import PropTypes from 'prop-types';

const Subtitle = ({ text, style }) => {
  let className = "text-sm ";
  if (style) {
    className = style;
  }
  const formattedText = text.split(`\n`).map((p, key) => (
    <div className="mt-4 mb-2 px-2 py-4 text-center" key={key}>
      <p className={className}>{p.replace(/\n/g, '<br/>')}</p>
    </div>
  ))
  return <div>
    {formattedText}
  </div>
};

export default Subtitle

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
};
