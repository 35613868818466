import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getStyles from './styles';
import { useStyles } from 'react-styles-hook';

const LateralFilterSellers = ({ sellersName, selectedSellersArray, filterBySeller }) => {
  const classes = useStyles(getStyles());
  // if true : show more sellers
  const [isShowing, setIsShowing] = useState(false);

  return (
    <div className="pl-6 pt-4">
      <p className="text-sm md:text-base text-orange-dark font-semibold pl-4">Vendeurs</p>
      {/* checkbox section : renders sellers */}
      {isShowing ?
        sellersName.map((label) => (
          <div className="pl-8">
            <fieldset className="space-y-5">
              <div className="relative flex items-start py-2">
                <div className="flex items-center h-5 pt-3">
                  <input
                    id={`seller-${label.node.corporateName}`}
                    value={label.node.corporateName}
                    aria-describedby={label.node.corporateName}
                    name={label.node.corporateName}
                    type="checkbox"
                    checked={selectedSellersArray?.includes(label.node.corporateName) ? true : false}
                    style={classes.checkbox}
                    className="w-5 h-5 text-orange-dark bg-orange-dark"
                    onChange={filterBySeller}
                  />
                </div>
                <div className="ml-3 text-s md:text-sm">
                  <label htmlFor={`seller-${label.node.corporateName}`} className="text-black">
                    {label.node.corporateName}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        ))
        :
        sellersName.map((label) => (
          <div className="pl-8">
            <fieldset className="space-y-5">
              <div className="relative flex items-start py-2">
                <div className="flex items-center h-5 pt-3">
                  <input
                    id={`seller-${label.node.corporateName}`}
                    value={label.node.corporateName}
                    aria-describedby={label.node.corporateName}
                    name={label.node.corporateName}
                    type="checkbox"
                    checked={(selectedSellersArray?.includes(label.node.corporateName)) ? true : false}
                    style={classes.checkbox}
                    className="w-5 h-5 text-orange-dark bg-orange-dark"
                    onChange={filterBySeller}
                  />
                </div>
                <div className="ml-3 text-s md:text-sm">
                  <label htmlFor={`seller-${label.node.corporateName}`} className="text-black">
                    {label.node.corporateName}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        )).slice(0, 4)
      }
      {(sellersName.length > 4) && (
        isShowing ?
          <div
            className="text-s md:text-sm font-medium underline pt-4 pl-8 outline:none"
            onClick={() => setIsShowing(false)}
          >
            En voir moins
          </div>
          :
          <div
            className="text-s md:text-sm font-medium underline pt-4 pl-8 outline:none"
            onClick={() => setIsShowing(true)}
          >
            En voir plus
          </div>
      )
      }
    </div>
  )
};

export default LateralFilterSellers

LateralFilterSellers.propTypes = {
  sellersName: PropTypes.array.isRequired,
  selectedSellersArray: PropTypes.array,
  filterBySeller: PropTypes.func
};
