const getStyles = (theme) =>({
  checkbox: {
    color: "#F5B091",
    outlineOffset: "transparent",
    outline: "transparent",
    borderRadius: 3
  }
});

export default getStyles;
