const getStyles = (theme) => ({
  filteringSection: {
    flexFlow: "column"
  },
  filtersContainer: {
    borderRight: "1px solid #AFAFAF"
  },
  filterMobileView: {
    zIndex: 1000000
  }
});

export default getStyles;