import React from 'react';
import PropTypes from 'prop-types';
import getStyles from './styles';
import { useStyles } from 'react-styles-hook';

const LateralFacetFilter = ({ attribute, facetFilter }) => {
  const classes = useStyles(getStyles());

  return (
    <>
      <div className="pl-6 pt-4">
        <p className="text-sm md:text-base text-orange-dark font-semibold pl-4">{attribute.name}</p>
        <div className="pl-8">
          <fieldset className="space-y-5">
            {attribute?.values?.map((valuesName, index) => (
              <div className="relative flex items-start">
                <div className="flex items-center h-5 pt-3">
                  <input
                    id={valuesName}
                    value={attribute?.valuesCodes[index]}
                    type="checkbox"
                    style={classes.checkbox}
                    className="w-5 h-5 text-orange-dark bg-orange-dark"
                    onChange={facetFilter}
                    />
                </div>
                <div className="ml-3 text-s md:text-sm">
                  <label htmlFor={valuesName} className="text-black">
                    {valuesName}
                  </label>
                </div>
              </div>
            ))}
          </fieldset>
        </div>
      </div>
    </>
  )
};

export default LateralFacetFilter

LateralFacetFilter.propTypes = {
  attribute: PropTypes.object.isRequired,
  facetFilter: PropTypes.func.isRequired
};
