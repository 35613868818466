// Generate meta seo and open graph from markdown file

export default function generateMeta(initMetas, markdownMeta) {
  if (markdownMeta) {
    Object.keys(markdownMeta).map((metaName, key) => {
      let name;
      if (metaName.startsWith("og")) {
        name = metaName.slice(0, 2) + ':' + metaName.slice(2);
      }
      return initMetas.push({
        name: name || metaName,
        content: Object.values(markdownMeta)[key]
      });
    })
  }
  return initMetas;
}