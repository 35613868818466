import i18n from "../../../i18n";
import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useSelector, useDispatch } from 'react-redux'
import { getSearchLaunched } from '../../state/reducer';
import { capitalizeFirst } from '../../utils/format';
import Layout from "../../components/Layout";
import Paging from "../../components/Paging";
import NavBarMenu from '../../components/NavBarMenu';
import CoverImage from "../../components/CoverImage";
import searchImgMobile from "../../../assets/signupMobile.png";
import searchImgDesktop from "../../../assets/signUpDesktop.png";
import ProductListCard from "../../components/ProductListCard";
import PriceFilter from "../../components/PriceFilter";
import Loader from "../../components/Loader";
import DropdownSelector from "../../components/DropdownSelector";
import LateralFilterSellers from '../../components/LateralFilter/LateralFilterSellers';
import LateralFacetFilter from '../../components/LateralFilter/LateralFacetFilter';
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";
import SnackBar from '../../components/SnackBar';
import { Transition } from '@headlessui/react';
import Subtitle from "../../components/Subtitle";
import { Pagination } from "../../components/Pagination";
import generateMeta from '../../utils/meta';

const SearchResult = ({ data, pageContext }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const classes = useStyles(getStyles());
  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  // i18n used for translation
  i18n(pageContext.locale);

  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const dispatch = useDispatch();

  const { isLoading, productContents, searchErrorMessage, query, sellers, category, snackBarLoading, snackBarOpen, snackBarMessage, snackBarError, page, lastPage } = useSelector((state) => ({
    isLoading: state.getIn(["root", "isLoading"]),
    productContents: state.getIn(["root", "searchResults"]),
    searchErrorMessage: state.getIn(["root", "searchErrorMessage"]),
    query: state.getIn(["root", "query"]),
    sellers: state.getIn(["root", "sellers"]),
    category: state.getIn(["root", "category"]),
    snackBarLoading: state.getIn(["pageList", "snackBarLoading"]),
    snackBarOpen: state.getIn(["pageList", "snackBarOpen"]),
    snackBarMessage: state.getIn(["pageList", "snackBarMessage"]),
    snackBarError: state.getIn(["pageList", "snackBarError"]),
    page: state.getIn(["root", "page"]),
    lastPage: state.getIn(["root", "lastPage"]),
  }));

  const searchQuery = query;
  const sellersOfQuery = sellers;
  const categoryOfQuery = category;
  const [searchCategory, setSearchCategory] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [searchPage, setSearchPage] = useState(null);
  const [searchPriceMin, setSearchPriceMin] = useState(null);
  const [searchPriceMax, setSearchPriceMax] = useState(null);
  const [searchBySeller, setSearchBySeller] = useState(null);
  const [searchFacets, setSearchFacets] = useState(null);
  // useState for filter by relevance (weight) and price (dropdown filter) :
  const [orderType, setOrderType] = useState(null);

  const today = new Date();

  // UseEffects used for filtering by price
  useEffect(() => {
    if (searchPriceMin || searchPriceMax || orderType || searchBySeller || searchFacets || searchCategory) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType + searchBySeller + searchCategory + JSON.stringify(searchFacets));
    }
  }, [searchPriceMin, searchPriceMax, orderType, searchBySeller, searchFacets, searchCategory]);

  useEffect(() => {
    if (searchPage) {
      setSearchKey(searchPage + "###" + searchPriceMin + searchPriceMax + orderType + searchBySeller + searchFacets + searchCategory);
    }
  }, [searchPage]);

  useEffect(() => {
    if (!searchKey) return;
    const searchPageToRequest = parseInt(searchKey.split("###")[0]);
    if ((searchPriceMin || searchPriceMax || orderType || searchBySeller || searchFacets)) {
      dispatch(getSearchLaunched({
        value: searchQuery,
        searchLocale: pageContext.locale,
        currentPath: pageContext.currentPath,
        orderType,
        searchPage: searchPageToRequest,
        searchCategory,
        searchPriceMin,
        searchPriceMax,
        searchBySeller,
        searchFacets,
        sellersOfQuery,
        categoryOfQuery
      }));
    }
  }, [searchKey]);

  const currentListMinSearchPrice = 0;
  const currentListMaxSearchPrice = 300;

  let currentProductList = [];
  if (productContents) {
    currentProductList = productContents.map((hit) => {
      const urlProductPage = `/${pageContext.locale}/produits/${hit.categoryPath}/${hit.urlProductPage}`;
      const startDate = hit.crossedPriceStartDate !== null && hit.crossedPriceStartDate !== "null" ? new Date(hit.crossedPriceStartDate) : null;
      const endDate = hit.crossedPriceEndDate !== null && hit.crossedPriceEndDate !== "null" ? new Date(hit.crossedPriceEndDate) : null;
      let price = hit.price;
      let crossedPrice = hit.crossedPriceWithVat;
      if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
        price = hit.crossedPriceWithVat;
        crossedPrice = null;
      }
      return {
        title: hit.title,
        id: hit.productId,
        image: {
          description: hit.title,
          file: {
            url: hit.imageUrl
          }
        },
        price: price,
        priceDescription: hit.priceDescription,
        crossedPrice: crossedPrice,
        corporateName: hit.sellerName,
        sellerId: hit.sellerId,
        urlProductPage: urlProductPage,
        flapSelectionZawema: hit.flapSelectionZawema,
        offerId: hit.offerId,
        goal: hit.goal,
        porosity: hit.porosity,
        careSteps: hit.careSteps
      }
    });
  }

  // array of checked sellers
  const [selectedSellersArray] = useState([]);
  // array of checked category
  const [selectedCategoriesArray] = useState([]);
  // array of checked facets
  const [selectedFacetsArray] = useState([]);

  const handleClick = (pageNumber) => {
    setSearchPage(pageNumber);
    if (pageNumber === 0) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType + searchBySeller + searchCategory + JSON.stringify(searchFacets));
    }
  }

  return (

    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >

      <Helmet>
        <html lang={pageContext.locale} />
        <title>Zawema - Résultats de votre recherche</title>
        {metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>

      {/* Filtering section in mobile view */}
      {isOpenFilter
        && <Transition
          show={isOpenFilter}
          as={Fragment}
          enter="transition-opacity duration-150 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="w-full h-full absolute top-0 bg-white" style={classes.filterMobileView}>
            <div className="w-full flex flex-col sticky bottom-0">
              <div className="w-full p-6 flex justify-end">
                <button
                  className='bg-orange-dark'
                  onClick={() => setIsOpenFilter(!isOpenFilter)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className={"flex flex-wrap"} style={classes.filteringSection}>
                <div className={"w-5/6 h-full pl-5 z-50 pb-14"}>
                  <h3 className="font-medium text-semi">Trier par :</h3>
                  <DropdownSelector
                    increasingPrice={() => { setOrderType("asc-price") }}
                    decreasingPrice={() => { setOrderType("desc-price") }}
                    decreasingWeight={() => { setOrderType("desc-weight") }}
                    orderType={orderType}
                  />
                </div>
                {/* Categories navigation checkboxes */}
                {category && category.length > 1
                  && (
                    <div className={"w-full mx-auto md:mx-0 pb-14 z-30"}>
                      <h3 className="font-medium text-base text-left my-4 pl-8">Affiner la <span className="font-medium text-semi text-orange-dark">catégorie</span></h3>
                      {category.map((label) => (
                        <div className="pl-8 my-8" >
                          <fieldset className="space-y-5">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5 pt-3">
                                <input
                                  id={`category-${label}`}
                                  aria-describedby={label}
                                  name={label}
                                  type="checkbox"
                                  checked={selectedCategoriesArray.includes(label) ? true : false}
                                  style={classes.checkbox}
                                  className="w-5 h-5 text-orange-dark bg-orange-dark"
                                  onChange={(el) => {
                                    // create an array of selected sellers when checkbox is checked
                                    const checkedCategorieNameValue = el.target.name;
                                    selectedCategoriesArray.includes(checkedCategorieNameValue) ?
                                      selectedCategoriesArray.splice(selectedCategoriesArray.indexOf(checkedCategorieNameValue), 1) :
                                      selectedCategoriesArray.push(checkedCategorieNameValue);
                                    setSearchCategory([...selectedCategoriesArray]);
                                  }}
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor={`category-${label}`} className="text-black">
                                  {capitalizeFirst(label)}
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      ))}
                    </div>
                  )}
                {/* This filter uses Algolia */}
                <div className={"w-5/6 h-full mx-auto pb-14 z-0"}>
                  <PriceFilter
                    initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                    onPriceChange={([min, max]) => {
                      setSearchPriceMin(min);
                      setSearchPriceMax(max);
                    }}
                    minimumPrice={searchPriceMin}
                    maxPrice={searchPriceMax}
                  />
                </div>
                {/* Filter by sellers with checkboxes */}
                <div className="w-5/6 h-full pl-5 pb-14 z-30">
                  <LateralFilterSellers
                    sellersName={sellers}
                    selectedSellersArray={selectedSellersArray}
                    filterBySeller={(el) => {
                      // create an array of selected sellers when checkbox is checked
                      const checkedSellerNameValue = el.target.attributes.value.value;
                      selectedSellersArray.includes(checkedSellerNameValue) ?
                        selectedSellersArray.splice(selectedSellersArray.indexOf(checkedSellerNameValue), 1) :
                        selectedSellersArray.push(checkedSellerNameValue);
                      setSearchBySeller([...selectedSellersArray]);
                    }}
                  />
                </div>
              </div>
              <div className='w-full mb-10 flex justify-center'>
                <button
                  className="w-1/2 bg-orange-dark shadow-lg py-2 font-bold text-base"
                  onClick={() => setIsOpenFilter(!isOpenFilter)}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Transition>}

      {/* Filter & display result on desktop */}
      {
        !isOpenFilter
        && <>
          <NavBarMenu
            firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
              return (
                sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
              )
            })}
            secondLevelCategories={data.menuSecondLevel.edges}
            thirdLevelCategories={data.menuThirdLevel.edges}
            lang={pageContext.locale}
          />

          {/* Cover image and title category section with conditional rendering*/}
          <CoverImage
            imageDesktop={searchImgDesktop}
            altDesktop="Recherche"
            imageMobile={searchImgMobile}
            altMobile="Recherche"
            title="Recherche"
          />

          {/* Container for filtering and results */}
          <div className="flex pb-14">
            {/* Filtering section on left side's page (desktop version) */}
            <div className="w-full hidden md:flex flex-col md:w-3/12" style={classes.filtersContainer}>
              <div className={"flex flex-wrap pl-5"} style={classes.filteringSection}>
                <div className={"w-full h-full py-6 z-40 pb-14"}>
                  <h3 className="font-medium text-semi">Trier par :</h3>
                  <DropdownSelector
                    increasingPrice={() => { setOrderType("asc-price") }}
                    decreasingPrice={() => { setOrderType("desc-price") }}
                    decreasingWeight={() => { setOrderType("desc-weight") }}
                    orderType={orderType}
                  />
                </div>
                {/* Categories navigation checkboxes */}
                {category && category?.length > 1
                  && (
                    <div className={"w-full mx-auto md:mx-0 pb-14 z-30"}>
                      <h3 className="font-medium text-base text-left my-4 pl-8">Affiner la <span className="font-medium text-semi text-orange-dark">catégorie</span></h3>
                      {category.map((label) => (
                        <div className="pl-8 my-8" >
                          <fieldset className="space-y-5">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5 pt-3">
                                <input
                                  id={`category-${label}`}
                                  aria-describedby={label}
                                  name={label}
                                  type="checkbox"
                                  checked={selectedCategoriesArray.includes(label)}
                                  style={classes.checkbox}
                                  className="w-5 h-5 text-orange-dark bg-orange-dark"
                                  onChange={(el) => {
                                    // create an array of selected sellers when checkbox is checked
                                    const checkedCategorieNameValue = el.target.name;
                                    selectedCategoriesArray.includes(checkedCategorieNameValue) ?
                                      selectedCategoriesArray.splice(selectedCategoriesArray.indexOf(checkedCategorieNameValue), 1) :
                                      selectedCategoriesArray.push(checkedCategorieNameValue);
                                    setSearchCategory([...selectedCategoriesArray]);
                                  }}
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor={`category-${label}`} className="text-black">
                                  {capitalizeFirst(label)}
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      ))}
                    </div>
                  )}
                {/* This filter uses Algolia */}
                <div className={"w-5/6 h-full mx-auto md:mx-0 pb-14 z-0"}>
                  <PriceFilter
                    initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                    onPriceChange={([min, max]) => {
                      setSearchPriceMin(min);
                      setSearchPriceMax(max);
                    }}
                    minimumPrice={searchPriceMin}
                    maxPrice={searchPriceMax}
                  />
                </div>
                {/* Filter by sellers with checkboxes */}
                <div className="w-full h-full mx-auto md:mx-0 pb-4 z-30">
                  <LateralFilterSellers
                    sellersName={sellers}
                    selectedSellersArray={selectedSellersArray}
                    filterBySeller={(el) => {
                      // create an array of selected sellers when checkbox is checked
                      const checkedSellerNameValue = el.target.attributes.value.value;
                      selectedSellersArray.includes(checkedSellerNameValue) ?
                        selectedSellersArray.splice(selectedSellersArray.indexOf(checkedSellerNameValue), 1) :
                        selectedSellersArray.push(checkedSellerNameValue);
                      setSearchBySeller([...selectedSellersArray]);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Results section with products cards on right side of the page */}
            <div className={"flex flex-col w-full md:w-9/12"}>
              {query && (
                <Subtitle
                  text={`Vos résultats de recherche pour : ${capitalizeFirst(query)}`}
                  style="font-medium text-semi text-orange-dark"
                />
              )}
              <div className={"pt-8 pl-2 2xl:pl-0 grid grid-cols-2 gap-x-4 gap-y-8 xl:grid-cols-3 2xl:grid-cols-4"}>
                {isLoading && (
                  <Loader />
                )}
                {!isLoading && currentProductList.map((currentProductListItem) => {
                  return (
                    <ProductListCard product={currentProductListItem} />
                  )
                })}
              </div>
              {!isLoading && query && currentProductList.length === 0 && (
                <div className="mt-4 text-center">
                  <p>Aucun résultat</p>
                </div>
              )}
              {!query &&
                <div className="mt-4 text-center">
                  <p>Commencez à effectuer une recherche</p>
                </div>
              }

              {query && currentProductList.length !== 0 && (
                <>
                  <Pagination
                    page={page}
                    handleClick={(page) => handleClick(page)}
                    lastPage={lastPage}
                  />
                </>
              )}

            </div>

            {/* Filter bottom in mobile version */}
            <div className='w-full flex justify-center fixed bottom-8'>
              <button
                className='md:hidden rounded-full bg-black text-white flex justify-center items-center py-2 px-4'
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              >
                Filtres
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </>
      }
      {!snackBarLoading && <SnackBar open={snackBarOpen} error={snackBarError} message={snackBarMessage} />}

    </Layout>
  );
};

export default SearchResult;

export const query = graphql`
      query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
        edges {
        node {
        slug
                categoryName
              }
            }
          }
      menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
      sort : {fields: [slug], order: ASC}) {
        edges {
        node {
        slug
                categoryName
                categoryEncartTitle
      parentCategory {
        slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
      menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: {slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
      sort : {fields: [slug], order: ASC}) {
        edges {
        node {
        slug
                categoryName
                categoryEncartTitle
      parentCategory {
        slug
                  categoryName
                  categoryEncartTitle
      parentCategory {
        slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
          }
      markdownRemark(fileAbsolutePath: {regex: "/search.md/"}) {
        html
        frontmatter {
            title
            description
            ogtitle
            ogdescription
        }
      }
    }
`